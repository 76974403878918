.film-detail{
  span{
    font-weight: 300;
    text-transform: uppercase;
    color: rgba($white, 0.5);
    display: inline-block;
    width: 100%;
    font-size: 0.75rem;
  }
  p{
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
  }
  ul{
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    font-size: 0.875rem;
    li:not(:last-child){
      padding-right: 0.75rem;
    }
  }
}

.documents-list{
  > div{
    background: #48486e;
    &:nth-child(odd){
      background: rgba(255, 255, 255, 0.05);
    }
  }
}