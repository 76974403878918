/*-- SIDEBAR --*/
.off-canvas-sidebar, .sidebar{
  background: $main-color;
  background: linear-gradient($main-color, $main-color-darken);
  height: calc(100vh - 45px);
  width: 230px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  box-shadow: 0 0 45px 0 rgba(0,0,0,0.6);
  margin-top: 25px;
  margin-left: 20px;
  border-radius: 5px;
  .sidebar-wrapper{
    width: 100%;
    min-height: 100%;
    max-height: calc(100vh - 705px);
    z-index: 4;
    position: relative;
    overflow: hidden;
  }
  .logo{
    position: relative;
    padding: 1rem .7rem;
    z-index: 4;
    &:after{
      content: '';
      position: absolute;
      bottom: 0;
      right: 15px;
      height: 1px;
      width: calc(100% - 30px);
      background: rgba($white, .5);
    }
  }
  .nav{
    margin-top: 20px;
    display: block;
    li>a{
      margin: 10px 15px 0;
      border-radius: 30px;
      color: $body-color;
      display: block;
      text-decoration: none;
      position: relative;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 1rem;
      padding: 10px 8px;
      line-height: 1.75rem;
      + div .nav{
        background: rgba($white, 0.2);
        margin: 5px 10px 0 30px;
        border-radius: 5px;
        li{
          > a{
            margin-top: 0;
            padding: 8px 0 8px 24px;
            font-size: 0.75rem;
            text-transform: inherit;
          }
          &:first-child{
            > a{
              margin: 0 15px
            }
          }
        }
      }
    }
    i{
      font-size: 1rem;
    }
  }
  &.mini-sidebar{
    cursor: pointer;
    width: 80px;
  }
}

@include media-breakpoint-up(lg) {
  .main-panel, .sidebar, .sidebar-wrapper{
    transition-property: top, bottom, width;
    transition-duration: .2s,.2s,.35s;
    transition-timing-function: linear, linear, ease;
  }
}

.mainnav-sidebar{
  .accordion-item{
    border: none;
    background: transparent;
  }
  .accordion-button{
    background: transparent;
    color: $white;
    &:not(.collapsed)::after{
      background-image: escape-svg($accordion-button-icon);
    }
  }
  .accordion-body{
    background: rgba($white, 0.2);
    padding: 1rem 0.375rem;
    .subnav-items{
      list-style: none;
      padding: 0;
      margin: 0;
      li{
        a{
          padding: 0.5rem 1.25rem;
          font-size: 0.875rem;
          color: $white;
          display: inline-block;
          width: 100%;
          &:hover{
            background: rgba($white, 0.2);
          }
        }
      }
    }
  }
}
.mini-sidebar{
  .accordion-item{
    .accordion-button{
      span{display: none}
    }
  }
}