.theme-style{
  &.react-datepicker{
    border: none
  }
  .react-datepicker__current-month{
    color: rgba($white, 0.8);
  }
  .react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover{
    border-radius: 100%;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
  .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected{
    border-radius: 100%;
    background: $main-color;
    &.react-datepicker__day{
      color: $white
    }
  }
  .react-datepicker__year-read-view--down-arrow, .react-datepicker__month-read-view--down-arrow, .react-datepicker__month-year-read-view--down-arrow, .react-datepicker__navigation-icon::before{
    border-color: rgba($white, 0.5);
  }
  .react-datepicker__header{
    background-color: darken($main-color, 25%);
    border-bottom: 0;
  }
  .react-datepicker__day-name {
    color: $white
  }
  .react-datepicker__day{
    color: $secondary;
  }
  .react-datepicker__navigation{
    top: 8px;
  }
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: darken($main-color, 25%);
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: darken($main-color, 25%);
}
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before {
  border-bottom-color: darken($main-color, 25%);
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after {
  border-top-color: darken($main-color, 25%);
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before {
  border-top-color: darken($main-color, 25%);
}


