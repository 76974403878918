.box-login{
  background: $bg-content;
  padding: 30px 20px;
  border-radius: 0.5rem;
  width: 100%;
  margin: 0 0.25rem;
  max-width: 340px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0,0.2);
  h4{
    text-transform: uppercase;
    letter-spacing: 4px;
    color: rgba($white, 0.25);
    position: relative;
  }
}
.login-wrapper .main-panel{
  background: linear-gradient(220deg, #282845, #572ca7, #5b5b81);
  background-size: 600% 600%;

  -webkit-animation: AnimationName 20s ease infinite;
  -moz-animation: AnimationName 20s ease infinite;
  animation: AnimationName 20s ease infinite;
}
@-webkit-keyframes AnimationName {
  0%{background-position:92% 0}
  50%{background-position:9% 100%}
  100%{background-position:92% 0}
}
@-moz-keyframes AnimationName {
  0%{background-position:92% 0}
  50%{background-position:9% 100%}
  100%{background-position:92% 0}
}
@keyframes AnimationName {
  0%{background-position:92% 0}
  50%{background-position:9% 100%}
  100%{background-position:92% 0}
}