.wrapper{
  position: relative;
  top: 0;
  height: 100vh;
  &.more-space{
    .content{
        padding-left: 120px;
    }
  }
}
.main-panel{
  position: relative;
  float: right;
  width: 100%;
  min-height: 100vh;
  border-top: 2px solid $main-color;
  background: $body-bg-custom;
  > .content{
    padding: 0 30px 20px 280px;
    transition: all .5s cubic-bezier(.685,0.473,0.346,1);
    .body-content{
      background: $bg-content;
      padding: 30px 20px;
      border-radius: 0.5rem;
      margin-top: 80px;
      h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
        text-transform: uppercase;
        letter-spacing: 4px;
        color: rgba($white, 0.25);
        position: relative;
        margin-bottom: 2rem;
      }
    }
  }
}
label{
  font-weight: 300;
  font-size: 0.75rem;
  text-transform: uppercase;
}
.people-list{
  margin: 0 0 1rem 0;
  padding: 0;
  list-style: none;
  li{
    padding: 0.5rem 1rem;
    background: rgba($bg-dark-color, 0.4);
    font-weight: 300;
    font-size: 0.825rem;
    &:nth-child(odd){
      background: rgba($bg-light-color, 0.7);

    }
  }
}
.addPersonBox{
  background: rgba($bg-light-color, 0.6);
  border-radius: 0.5rem;
  position: relative;
  padding: 0.5rem 1rem 1rem;
  &:before{
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: auto;
    right: 10px;
    color: rgba($bg-light-color, 0.6);
    border-bottom: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
  }
  .form-control{
    margin-bottom: 0;
  }
}
.detail-file{
  background: rgba($bg-light-color, 0.6);
  padding: 0.5rem 1rem 1rem;
  position: relative;
  margin-top: 5px;
  border-radius: 0.5rem;
  &:before{
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: "";
    top: -5px;
    left: 10px;
    right: auto;
    color: rgba($bg-light-color, 0.6);
    border-bottom: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
  }
}

.uploaded-box-image {
  background: rgba($bg-light-color, 0.6);
  padding: 0.5rem 0.75rem 0.75rem;
  border-radius: 0.25rem;
  margin-bottom: 0.75rem;
  p {
    margin-bottom: 0.25rem;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: rgba($white,.4);
  }
}
.bg-group-home{
  background: rgba($bg-light-color, 0.6);
  padding: 1rem 0;
  border-radius: 0.5rem;
  .box-group-home {
    display: flex;
    flex-direction: column;
    background: $main-color;
    color: $white;
    padding: 2rem;
    border-radius: 0.25rem;
    align-items: center;
    transition: .75s all;
    &:hover{
      background: darken($main-color, 10%);
    }
    .icon{
      font-size: 5rem;
      margin-bottom: 1.5rem;
    }
    span {
      font-size: 1.5rem;
      letter-spacing: 2px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}
.box-list-size{
  color: rgba($white, .7);
  .list-size{
    font-size: 0.75rem;
    list-style: none;
    padding: 0;
  }
}
.nav-pills .nav-link{
  cursor: pointer;
}