@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap');

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;

$main-color: #7C3AED;

$blue:    #0d6efd !default;
$indigo:  #7C3AED !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;

$primary:       $main-color !default;
$secondary:     $gray-600 !default;
$success:       $pink !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "info":       $info,
        "warning":    $warning,
        "danger":     $danger,
        "light":      $light,
        "dark":       $dark
);

$font-family-sans-serif: 'Open Sans', sans-serif;
$main-color-darken: darken($main-color, 20%);
$white: #fff;
$secondary: #6c757d;

$bg-dark-color: #1e1e24;
$bg-light-color: #1e1e2f;
$body-bg-custom: linear-gradient($bg-light-color, $bg-dark-color);
$bg-content: lighten($bg-light-color, 10%);
$body-color: $white;

$link-decoration: none;
$headings-font-weight: 300;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $secondary;
$breadcrumb-active-color: $secondary;

//forms
$input-font-size: 0.75rem;
$input-bg: $bg-content;
$input-color: $white;
$input-border-radius: .25rem;

$form-file-button-bg: $main-color;

$tooltip-font-size: 0.75rem;
$tooltip-bg: $bg-dark-color;

