
/*-- HEADER --*/
.main-panel .navbar{
  margin-bottom: 0;
}
.navbar{
  width: 100%;
  &.navbar-absolute{
    position: absolute;
    z-index: 1050;
  }
  .navbar-wrapper{
    display: flex;
    align-items: center;
  }
  .btn{
    margin: 0 5px 0 10px;
  }
}
.navbar-nav{
  li{
    padding: 0 10px;
  }
  .nav-link.dropdown-item {
    padding: 0.25rem 0.75rem;
    > a{
      color: $secondary;
      font-size: 0.75rem;
    }
  }
  .dropdown-menu{
    padding: 0.5rem;
    >li.nav-link:not(:last-child){
      margin-bottom: 0.5rem;
    }
  }
}
.dropdown-menu{
  right: 0;
  left: auto !important;
  &:before{
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    vertical-align: middle;
    content: '';
    top: -5px;
    left: 10px;
    right: auto;
    color: $white;
    border-bottom: .4em solid;
    border-right: .4em solid transparent;
    border-left: .4em solid transparent;
  }
  &.dropdown-menu-right:before{
    left: auto;
    right: 10px;
  }
  &.dropdown-navbar:before{
    left: auto;
    right: 17px;
  }
}

.dropdown-user{
  .dropdown-menu{
    background: rgba($white, .95);
    border: none;
    padding: 0.5rem;
    &:before{
      left: auto;
      right: 10px;
    }
    a{
      color: $secondary;
      font-size: 0.825rem;
      transition: all 0.5s;
      &:not(:last-child){
        margin-bottom: 0.5rem;
      }
      &:hover{
        background: rgba($main-color, .75);
        color: $white
      }
    }
  }
}