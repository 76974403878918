/*-- SUGGESTION --*/

.react-autosuggest__container {
  position: relative;
  width: 100%;
  margin-right: 0.5rem;
}

.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 32px;
  width: 100%;
  border: 1px solid $main-color;
  background-color: rgba($bg-content, .95);
  font-weight: 300;
  font-size: 0.75rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  z-index: 2;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: rgba($main-color, .2);
  color: $white;
}

.form-control, .form-select{
  margin-bottom: 1rem;
}
